<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.centers") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="parsm.type"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          clearable
          @change="stauts" 
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.la')" value="1"></el-option>
          <el-option :label="$t('lang.tui')" value="2"></el-option>
          <el-option :label="$t('lang.pullzip')" value="3"></el-option>
        </el-select>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
       <el-table-column align="center" :label="$t('lang.paymentdays')" width="150">
       	<template slot-scope="scope">
       	  <span>{{scope.row.createTime | times}}</span>
       	</template>
       </el-table-column>
        <el-table-column prop="email" align="center" :label="$t('lang.accountnumber')" width="220">
        </el-table-column>
        <el-table-column :label="$t('lang.billnumber')" prop="sn" width="220" align="center"></el-table-column>
        <el-table-column
          :label="$t('lang.Thebilltype')"
          align="center"
          width="130"
        >
        <template #default="scope">
            <span v-if="scope.row.type==0">{{$t('lang.Flowbill')}}</span>
            <span v-else>{{$t('lang.Storebills')}}</span>
          </template>
        </el-table-column>
         <el-table-column :label="$t('lang.dischargepattern')"  width="200" align="center">
          <template #default="scope">
             <span v-if="scope.row.content==1">{{$t('lang.la')}}</span>
             <span v-if="scope.row.content==2">{{$t('lang.tui')}}</span>
             <span v-if="scope.row.content==3">{{$t('lang.pullzip')}}</span>
          </template>
        </el-table-column>
				<el-table-column prop="media" align="center" :label="$t('lang.route')" width="300">
				</el-table-column>
        <el-table-column align="center" :label="$t('lang.consumptiontime')" width="440">
        	<template slot-scope="scope">
        	  <span>{{scope.row.startTime}}——</span>
        	  <span>{{scope.row.endTime}}</span>
        	</template>
        </el-table-column>
        <el-table-column :label="$t('lang.targetIp')"  prop="ip"  width="150" align="center">
        </el-table-column>
				<el-table-column align="center" :label="$t('lang.fileSize')" width="300">
					<template slot-scope="scope">
					  <span v-if="scope.row.devMid">{{scope.row.devMid}}</span>
						<span v-else>/</span>
					</template>
				</el-table-column>
        <el-table-column :label="$t('lang.dosage')" width="150" align="center">
					<template #default="scope">
					  <span>{{ scope.row.amount |amoutsa }}GB</span>
					</template>
        </el-table-column>
        <el-table-column :label="$t('lang.balance')" width="100" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.money |monsa}}</span>
					</template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; align-items: center"
        v-if="tableData.length > 0"
      >
        <div class="allNum">{{ $t("lang.total") }}: {{ alltoals }}</div>
        <el-pagination
          background
          :current-page="parsm.page"
          v-if="tableData.length > 0"
          layout="prev, pager, next"
          :total="alltoals"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as workmsg from "@/api/manage.js";
export default {
  data() {
    return {
      seenFreetext: this.$t("lang.details"),
      time: "",
      value1: "",
      tableData: [],
      alltoals:"",
      parsm: {
        page: 1,
        size: 10,
        type:"",
        sn:"",
				email:''
      },
    };
  },
  created() {
    if(this.$route.query.email){
    	this.parsm.email=this.$route.query.email
			this.parsm.sn=this.$route.query.sn
    }
    this.getList();
  },
  filters:{
  	times(val){
  		let skr=''
  		if(val){
  			let sk=val.split(' ')
  			let skk=sk[0].split('-')
  			skr=skk[0]+'-'+skk[1]
  		}
  		return skr
  	},
		amoutsa(val){
			if(val){
				return val.toFixed(4)
			}else{
				return val
			}
		},
		monsa(val){
			if(val){
				return val.toFixed(2)
			}else{
				return val
			}
		}
  },
  methods: {

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.createTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.createTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //状态筛选
    stauts(e) {
      this.parsm.page = 1;
			this.parsm.type=e
			this.parsm.page=1
			this.getList();
    },
    //获取工单列表
    async getList() {
      let res = await workmsg.billListDetailApi(this.parsm);
      console.log(res);
      if (res.data) {
        this.tableData = res.data.content;
        this.alltoals = res.data.totalElements
      }
    },
    //详情
    handleEdit(row) {
      this.$router.push({
        path: "billdetail",
        query: { id: row.id, page: this.parsm.page },
      });
    },
    // 关闭工单
    closemsg(row) {
      workmsg.closeWork({ id: row.id }).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.messige {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  background: red;
  top: 0.05rem;
  left: 0.2rem;
  border-radius: 50%;
}
.texts {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
